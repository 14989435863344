import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userdata: null
  },
  mutations: {
    userdata: (state, userdata) => {
      state.userdata = userdata;
    }
  },
  actions: {
    userdata: ({state, commit}, userdata) => {
      commit('userdata', userdata);
    }
  }
});
