import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Erhebungsperioden from '../views/Erhebungsperioden.vue';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'eStrab Home',
      roles: ['Anonym', 'Leser', 'Benutzer', 'Bearbeiter', 'Administrator']
    }
  },
  {
    path: '/Datenbank',
    name: 'Datenbank',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Datenbank.vue'),
    meta: {
      title: 'eStrab Datenbank',
      roles: ['Administrator']
    }
  },
  {
    path: '/Benutzerverwaltung',
    name: 'Benutzerverwaltung',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Benutzerverwaltung.vue'),
    meta: {
      title: 'eStrab Benutzerverwaltung',
      roles: ['Administrator']
    }
  },
  {
    path: '/Einstellungen',
    name: 'Einstellungen',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Einstellungen.vue'),
    meta: {
      title: 'eStrab Einstellungen',
      roles: ['Administrator']
    }
  },
  {
    path: '/Erhebungsperioden',
    name: 'Erhebungsperioden',
    component: Erhebungsperioden,
    meta: {
      title: 'eStrab Erhebungsperioden',
      roles: ['Administrator']
    }
  },
  {
    path: '/Karte',
    name: 'Karte',
    beforeEnter: (to, from, next) => {
      window.location.href = '/client/index.html';
    },
    meta: {
      roles: ['Bearbeiter', 'Leser', 'Benutzer', 'Administrator']
    }
  },
  {
    path: '/SyncButler',
    name: 'SyncButler',
    beforeEnter: (to, from, next) => {
      window.location.href = '/client/sync-butler/syncer.html';
    },
    meta: {
      roles: ['Administrator', 'Bearbeiter']
    }
  },
  {
    path: '/Login',
    component: Login,
    meta: {
      title: 'eStrab Login',
      roles: ['Anonym', 'Leser', 'Bearbeiter', 'Benutzer', 'Administrator']
    }
  },
  {
    path: '/Logout',
    name: 'Logout',
    component: {
      async beforeRouteEnter(to, from, next) {
        try {
          await fetch('../api/logout');
          store.dispatch('userdata', undefined);
          next('/Login');
        } catch (e) {
          next('/');
        }
      }
    },
    meta: {
      roles: ['Anonym', 'Leser', 'Bearbeiter', 'Benutzer', 'Administrator']
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach(async (to, from, next) => {
  const errNext = {path: '/Login', query: {next: to.query.next || to.fullPath}};
  const userdata = store.state.userdata;
  if (!userdata || userdata.rolle === 'Anonymous') {
    try {
      const response = await fetch('/api/benutzer/info');
      const userdata = await response.json();
      store.dispatch('userdata', userdata);
    } catch (err) {
      //
    }
  }
  document.title = to.meta.title || 'eStrab';
  const allowedRoles = to.meta.roles || [];
  if (allowedRoles.includes(store.state.userdata.rolle)) {
    if (to.path !== '/Login' && to.query.next) {
      next({
        path: to.query.next
      });
    } else {
      next({query: to.query});
    }
  } else {
    next(errNext);
  }
});

export default router;
