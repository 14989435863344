<template>
  <v-container
    fluid
    :class="{
      'largePage':$vuetify.breakpoint.mdAndUp,
      'px-5':$vuetify.breakpoint.smAndDown,
    }"
  >
    <div class="text-h4 my-4">
      Verwaltung der Erhebungsperioden je Gemeinde
    </div>
    <v-divider class="mb-4" />
    <div class="d-flex align-center">
      <v-autocomplete
        v-model="selectedRegionalcode"
        :items="regionalcodes"
        :disabled="creatingNewPeriode"
        outlined
        dense
        hide-details
        item-text="displayname"
        item-value="id"
        placeholder="Gemeinde"
        class="mr-4"
        @change="(value) => {$router.push({query: {regionalcode: value}})}"
      />
      <v-btn
        color="primary"
        :disabled="!selectedRegionalcode"
        :loading="creatingNewPeriode"
        @click="createNewPeriode()"
      >
        Neue Periode
      </v-btn>
    </div>
    <v-data-table
      v-if="snapshots.length"
      class="mt-4"
      :headers="headers"
      :items="snapshots"
      :item-key="'timestamp'"
      disable-filtering
      disable-sort
      hide-default-footer
    >
      <template
        #item.Erhebungsperiode="{ item, index }"
      >
        {{ getNameForIndex(index) }}
      </template>
      <template #item.beginn="{ item, index }">
        {{ getBeginnForTimestamp(index) }}
      </template>
      <template #item.Fertigstellung="{ item, index }">
        <span
          v-if="index === 0"
          class="pl-3"
        >-</span>
        <v-progress-linear
          v-else-if="item.progress !== null"
          :value="item.progress || 0"
          color="amber"
          height="25"
        >
          <template #default="{ value }">
            <strong>{{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>
      </template>
      <template #item.actions="{ item, index }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              v-if="index !== snapshots.length - 1"
              text
              class="actionButton"
              v-bind="attrs"
              v-on="on"
              @click="deletePeriode(item, index)"
            >
              <v-icon
                color="error"
              >
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <span> Snapshot löschen </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              text
              class="actionButton"
              v-bind="attrs"
              @click="goToGemeinde(item, index)"
              v-on="on"
            >
              <v-icon
                class="mx-2"
              >
                mdi-map
              </v-icon>
            </v-btn>
          </template>
          <span> Ansicht im Erhebungsclient </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              v-if="index !== snapshots.length - 1"
              text
              class="actionButton"
              :href="getDownloadLink(item)"
              v-bind="attrs"
              download
              v-on="on"
            >
              <v-icon>
                mdi-download
              </v-icon>
            </v-btn>
          </template>
          <span> Snapshot downloaden </span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>

export default {
  name: 'Erhebungsperioden',
  data: () => ({
    dialog: false,
    headers: [{text: 'Erhebungsperiode', value: 'Erhebungsperiode', width: '220'},
      {text: 'Beginn', value: 'beginn', width: '220'},
      {text: 'Fertigstellung', value: 'Fertigstellung'},
      {text: '', value: 'actions', width: '220', sortable: false}],
    regionalcodes: [], // all regional codes
    selectedRegionalcode: null,
    creatingNewPeriode: false, // used for loading animation
    snapshots: [] // currently loaded snapshots
  }),
  computed: {
    currentGemeinde() {
      return this.regionalcodes.find(r => r.id === this.snapshots[0].regionalcode).displayname;
    }
  },
  watch: {
    '$route.query.regionalcode'(value) {
      this.selectedRegionalcode = value;
    },
    selectedRegionalcode(value) {
      this.fetchSnapshots(value);
    }
  },
  async created() {
    const response = await fetch('../api/lut');
    const lut = await response.json();
    this.regionalcodes.push(...lut.lut_regionalcode.sort((a, b) => { // sort gemeinden alphabetically
      if (a['displayname'] < b['displayname']) {
        return -1;
      } else {
        return 1;
      }
    }));
    const regionalCodeFromUrl = this.$router.currentRoute.query.regionalcode;
    if (regionalCodeFromUrl) {
      this.selectedRegionalcode = regionalCodeFromUrl;
      this.fetchSnapshots(regionalCodeFromUrl);
    }
  },
  methods: {
    async fetchSnapshots(value) {
      this.snapshots.length = 0;
      const response = await fetch(`../api/snapshot?regionalcode=${value}`);
      const snapshots = await response.json();
      snapshots.sort((a, b) => {
        return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
      });
      snapshots.push({
        href: `/api/snapshot/${value}`,
        regionalcode: value,
        timestamp: ''
      });
      snapshots.forEach(s => s.progress = null);
      this.snapshots.splice(0, snapshots.length, ...snapshots);
      // calculating the progress, this can by async
      this.snapshots.forEach(async (snapshot) => {
        let data;
        if (snapshot.timestamp) {
          const downloadLink = this.getDownloadLink(snapshot);
          const response = await fetch(downloadLink + '/?format=json');
          data = await response.json();
        } else {
          // current Erhebung
          try {
            const response = await fetch(`../api/ueberprueft/${value}`);
            data = await response.json();
          } catch (e) {
            data = [];
          }
        }
        const ueberprueft = data.filter(o => o.ueberprueft).length;
        snapshot.progress = Math.round(100 * ueberprueft / data.length);
      });
    },
    getNameForIndex(index) {
      if (index === 0) {
        return 'Erhebung 2018';
      }
      const timestamp = this.snapshots[index - 1].timestamp;
      const date = new Date(timestamp).getFullYear();
      return `Erhebung ${date}`;
    },
    getBeginnForTimestamp(index) {
      if (index === 0) {
        return '01.01.2018';
      }

      const date = new Date(this.snapshots[index - 1].timestamp);
      return date.toLocaleDateString('de-AT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    },
    toPrecision(precision, value) {
      const precisionFactor = Math.pow(10, precision);
      return (Math.round(value * precisionFactor) / precisionFactor).toString();
    },
    async createNewPeriode() {
      if (await this.$root.$confirm({
        title: 'Erhebungsperiode erstellen',
        message: 'Soll eine neue Erhebungsperiode erstellt werden? Diese Aktion setzt den Status aller Zustände dieser Gemeinde auf "nicht überprüft."'
      })) {
        this.creatingNewPeriode = true;
        await fetch(`../api/snapshot/${this.selectedRegionalcode}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        await this.fetchSnapshots(this.selectedRegionalcode);
        this.creatingNewPeriode = false;
      }
    },
    async deletePeriode(item, index) {
      //@ts-ignore
      const ok = await this.$root.$confirm({
        title: 'Erhebungsperiode löschen',
        message: `Soll die gewählte ${this.getNameForIndex(index)} wirklich gelöscht werden?`
      });
      if (ok) {
        const time = new Date(item.timestamp).getTime();
        const response = await fetch(`../api/snapshot/${item.regionalcode}/${time}`, {
          method: 'DELETE'
        });
        if (response.ok) {
          await this.fetchSnapshots(item.regionalcode);
        }
      }
    },
    async goToGemeinde(item) {
      let hash = `#/regionalcode/${item.regionalcode}/zustandskarte/aktuell`;
      if (item.timestamp) {
        hash += `/zustandskarte/${new Date(item.timestamp).getTime()}`;
      }
      return window.open('../client/index.html' + hash);
    },
    getDownloadLink(item) {
      const time = new Date(item.timestamp).getTime();
      return `../api/snapshot/${item.regionalcode}/${time}`;
    }
  }
};
</script>

<style>
  .largePage {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .actionButton {
    min-width: 36px !important;
    padding: 0px !important;
  }
</style>

