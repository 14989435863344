var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        largePage: _vm.$vuetify.breakpoint.mdAndUp,
        "mt-5": _vm.$vuetify.breakpoint.mdAndUp,
        "px-3": _vm.$vuetify.breakpoint.smAndDown
      }
    },
    _vm._l(_vm.visibleHeaderItems, function(item, index) {
      return _c(
        "v-card",
        {
          key: index,
          class: {
            "pa-4": true,
            "ml-10": _vm.$vuetify.breakpoint.mdAndUp,
            "text-h4": _vm.$vuetify.breakpoint.mdAndUp,
            "text-h6": _vm.$vuetify.breakpoint.smAndDown
          },
          attrs: { flat: "", to: item.route }
        },
        [_vm._v("\n    " + _vm._s(item.text) + "\n  ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }