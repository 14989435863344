<template>
  <v-app id="app">
    <Confirm ref="confirm" />
    <v-app-bar
      app
    >
      <v-toolbar-items
        class="toolbar hidden-md-and-down"
      >
        <v-btn
          v-for="(item, index) in visibleHeaderItems"
          :key="index"
          depressed
          tile
          x-small
          :to="item.route"
        >
          <v-icon left>
            {{ item.icon }}
          </v-icon>
          {{ item.text }}
        </v-btn>
        <v-spacer />
        <v-sheet
          v-if="isLoggedIn"
          tile
          class="userNameDisplay d-flex"
        >
          <v-icon> mdi-account </v-icon> {{ userName }}
        </v-sheet>
        <v-btn
          v-if="isLoggedIn"
          tile
          depressed
          class="logoutButton mx-4"
          x-small
          to="/Logout"
        >
          <v-icon> mdi-logout </v-icon>
          Logout
        </v-btn>
        <v-btn
          v-else
          tile
          depressed
          class="logoutButton mx-4"
          to="/Login"
        >
          <v-icon> mdi-login </v-icon>
          Login
        </v-btn>
      </v-toolbar-items>
      <v-app-bar-nav-icon
        class="hidden-lg-and-up"
        @click.stop="drawer = !drawer"
      />
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
    >
      <v-list>
        <v-list-item-group>
          <v-list-item
            v-for="item in visibleHeaderItems"
            :key="item.text"
            :to="item.route"
          >
            <v-icon>{{ item.icon }}</v-icon>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="isLoggedIn"
            to="/Logout"
          >
            <v-icon> mdi-logout </v-icon>
            <v-list-item-title>
              Logout {{ userName }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-else
            to="/Login"
          >
            <v-icon> mdi-login </v-icon>
            <v-list-item-title>
              Login
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Confirm from './components/Confirm.vue';
import {headerItems} from './components/helpers';

export default {
  components: {
    Confirm
  },
  data: () => ({
    drawer: false,
    message: 'Hello World',
    headerItems
  }),
  computed: {
    visibleHeaderItems: function() {
      return this.headerItems.filter(item => {
        //@ts-ignore
        return !item.rolle || item.rolle.includes(this.currentRolle);
      });
    },
    isLoggedIn: function() {
      //@ts-ignore
      return this.$store.state.userdata && this.$store.state.userdata.rolle !== 'Anonym';
    },
    currentRolle: function() {
      //@ts-ignore
      return this.$store.state.userdata ? this.$store.state.userdata.rolle : null;
    },
    userName: function() {
      //@ts-ignore
      return this.$store.state.userdata.username;
    }
  },
  mounted() {
    //@ts-ignore
    this.$root.$confirm = this.$refs.confirm.open;
  }
};
</script>

<style>
  #app {
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
  }

  .toolbar {
    width: 100%;
  }

  .logoutButton {
    right: 0px;
    position: absolute !important;
  }

    .userNameDisplay {
    padding-right: 130px;
    background-color: transparent !important;
    line-height: 64px;
    font-weight: 500;
    font-size: 10px;
    letter-spacing: .0892857143em;
    text-indent: .0892857143em;
  }
</style>
