var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      class: {
        largePage: _vm.$vuetify.breakpoint.mdAndUp,
        "px-5": _vm.$vuetify.breakpoint.smAndDown
      },
      attrs: { fluid: "" }
    },
    [
      _c("div", { staticClass: "text-h4 my-4" }, [
        _vm._v("\n    Verwaltung der Erhebungsperioden je Gemeinde\n  ")
      ]),
      _vm._v(" "),
      _c("v-divider", { staticClass: "mb-4" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c("v-autocomplete", {
            staticClass: "mr-4",
            attrs: {
              items: _vm.regionalcodes,
              disabled: _vm.creatingNewPeriode,
              outlined: "",
              dense: "",
              "hide-details": "",
              "item-text": "displayname",
              "item-value": "id",
              placeholder: "Gemeinde"
            },
            on: {
              change: function(value) {
                _vm.$router.push({ query: { regionalcode: value } })
              }
            },
            model: {
              value: _vm.selectedRegionalcode,
              callback: function($$v) {
                _vm.selectedRegionalcode = $$v
              },
              expression: "selectedRegionalcode"
            }
          }),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                color: "primary",
                disabled: !_vm.selectedRegionalcode,
                loading: _vm.creatingNewPeriode
              },
              on: {
                click: function($event) {
                  return _vm.createNewPeriode()
                }
              }
            },
            [_vm._v("\n      Neue Periode\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.snapshots.length
        ? _c("v-data-table", {
            staticClass: "mt-4",
            attrs: {
              headers: _vm.headers,
              items: _vm.snapshots,
              "item-key": "timestamp",
              "disable-filtering": "",
              "disable-sort": "",
              "hide-default-footer": ""
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.Erhebungsperiode",
                  fn: function(ref) {
                    var item = ref.item
                    var index = ref.index
                    return [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.getNameForIndex(index)) +
                          "\n    "
                      )
                    ]
                  }
                },
                {
                  key: "item.beginn",
                  fn: function(ref) {
                    var item = ref.item
                    var index = ref.index
                    return [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.getBeginnForTimestamp(index)) +
                          "\n    "
                      )
                    ]
                  }
                },
                {
                  key: "item.Fertigstellung",
                  fn: function(ref) {
                    var item = ref.item
                    var index = ref.index
                    return [
                      index === 0
                        ? _c("span", { staticClass: "pl-3" }, [_vm._v("-")])
                        : item.progress !== null
                        ? _c("v-progress-linear", {
                            attrs: {
                              value: item.progress || 0,
                              color: "amber",
                              height: "25"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var value = ref.value
                                    return [
                                      _c("strong", [
                                        _vm._v(_vm._s(Math.ceil(value)) + "%")
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "item.actions",
                  fn: function(ref) {
                    var item = ref.item
                    var index = ref.index
                    return [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    index !== _vm.snapshots.length - 1
                                      ? _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "actionButton",
                                                attrs: { text: "" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deletePeriode(
                                                      item,
                                                      index
                                                    )
                                                  }
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "error" } },
                                              [
                                                _vm._v(
                                                  "\n              mdi-delete\n            "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [_vm._v(" Snapshot löschen ")])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "actionButton",
                                            attrs: { text: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.goToGemeinde(
                                                  item,
                                                  index
                                                )
                                              }
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", { staticClass: "mx-2" }, [
                                          _vm._v(
                                            "\n              mdi-map\n            "
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [_vm._v(" Ansicht im Erhebungsclient ")])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    index !== _vm.snapshots.length - 1
                                      ? _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "actionButton",
                                                attrs: {
                                                  text: "",
                                                  href: _vm.getDownloadLink(
                                                    item
                                                  ),
                                                  download: ""
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                "\n              mdi-download\n            "
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [_vm._v(" Snapshot downloaden ")])
                        ]
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              2284010871
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }