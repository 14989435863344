<template>
  <div
    :class="{
      'largePage':$vuetify.breakpoint.mdAndUp,
      'mt-5':$vuetify.breakpoint.mdAndUp,
      'px-3':$vuetify.breakpoint.smAndDown,
    }"
  >
    <v-card
      v-for="(item, index) in visibleHeaderItems"
      :key="index"
      flat
      :class="{
        'pa-4': true,
        'ml-10': $vuetify.breakpoint.mdAndUp,
        'text-h4': $vuetify.breakpoint.mdAndUp,
        'text-h6': $vuetify.breakpoint.smAndDown
      }"
      :to="item.route"
    >
      {{ item.text }}
    </v-card>
  </div>
</template>

<script>
import {headerItems} from '../components/helpers';

export default {
  name: 'Home',
  data: () => ({
    headerItems
  }),
  computed: {
    visibleHeaderItems: function() {
      return this.headerItems.filter(item => {
        //@ts-ignore
        return item.route !== '/' && (!item.rolle || item.rolle.includes(this.currentRolle));
      });
    },
    currentRolle: function() {
      //@ts-ignore
      return this.$store.state.userdata ? this.$store.state.userdata.rolle : null;
    }
  }
};
</script>

<style>
  .largePage {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
</style>

