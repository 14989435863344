var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "600px",
        "overlay-opacity": "0.6",
        elevation: "8",
        persistent: ""
      },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.cancel($event)
        }
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              attrs: {
                dark: "",
                color: "red darken-2 text-none",
                dense: "",
                flat: ""
              }
            },
            [
              _c("v-toolbar-title", { attrs: { dark: "" } }, [
                _vm._v("\n        " + _vm._s(_vm.title) + "\n      ")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !!_vm.message,
                  expression: "!!message"
                }
              ],
              staticClass: "pa-4"
            },
            [_vm._v("\n      " + _vm._s(_vm.message) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            { staticClass: "pt-0" },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary darken-1", text: "" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.agree($event)
                    }
                  }
                },
                [_vm._v("\n        OK\n      ")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "grey", text: "" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.cancel($event)
                    }
                  }
                },
                [_vm._v("\n        Abbrechen\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }