var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "app" } },
    [
      _c("Confirm", { ref: "confirm" }),
      _vm._v(" "),
      _c(
        "v-app-bar",
        { attrs: { app: "" } },
        [
          _c(
            "v-toolbar-items",
            { staticClass: "toolbar hidden-md-and-down" },
            [
              _vm._l(_vm.visibleHeaderItems, function(item, index) {
                return _c(
                  "v-btn",
                  {
                    key: index,
                    attrs: {
                      depressed: "",
                      tile: "",
                      "x-small": "",
                      to: item.route
                    }
                  },
                  [
                    _c("v-icon", { attrs: { left: "" } }, [
                      _vm._v("\n          " + _vm._s(item.icon) + "\n        ")
                    ]),
                    _vm._v("\n        " + _vm._s(item.text) + "\n      ")
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.isLoggedIn
                ? _c(
                    "v-sheet",
                    {
                      staticClass: "userNameDisplay d-flex",
                      attrs: { tile: "" }
                    },
                    [
                      _c("v-icon", [_vm._v(" mdi-account ")]),
                      _vm._v(" " + _vm._s(_vm.userName) + "\n      ")
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isLoggedIn
                ? _c(
                    "v-btn",
                    {
                      staticClass: "logoutButton mx-4",
                      attrs: {
                        tile: "",
                        depressed: "",
                        "x-small": "",
                        to: "/Logout"
                      }
                    },
                    [
                      _c("v-icon", [_vm._v(" mdi-logout ")]),
                      _vm._v("\n        Logout\n      ")
                    ],
                    1
                  )
                : _c(
                    "v-btn",
                    {
                      staticClass: "logoutButton mx-4",
                      attrs: { tile: "", depressed: "", to: "/Login" }
                    },
                    [
                      _c("v-icon", [_vm._v(" mdi-login ")]),
                      _vm._v("\n        Login\n      ")
                    ],
                    1
                  )
            ],
            2
          ),
          _vm._v(" "),
          _c("v-app-bar-nav-icon", {
            staticClass: "hidden-lg-and-up",
            on: {
              click: function($event) {
                $event.stopPropagation()
                _vm.drawer = !_vm.drawer
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-navigation-drawer",
        {
          attrs: { app: "" },
          model: {
            value: _vm.drawer,
            callback: function($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer"
          }
        },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item-group",
                [
                  _vm._l(_vm.visibleHeaderItems, function(item) {
                    return _c(
                      "v-list-item",
                      { key: item.text, attrs: { to: item.route } },
                      [
                        _c("v-icon", [_vm._v(_vm._s(item.icon))]),
                        _vm._v(" "),
                        _c("v-list-item-title", [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.text) +
                              "\n          "
                          )
                        ])
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _vm.isLoggedIn
                    ? _c(
                        "v-list-item",
                        { attrs: { to: "/Logout" } },
                        [
                          _c("v-icon", [_vm._v(" mdi-logout ")]),
                          _vm._v(" "),
                          _c("v-list-item-title", [
                            _vm._v(
                              "\n            Logout " +
                                _vm._s(_vm.userName) +
                                "\n          "
                            )
                          ])
                        ],
                        1
                      )
                    : _c(
                        "v-list-item",
                        { attrs: { to: "/Login" } },
                        [
                          _c("v-icon", [_vm._v(" mdi-login ")]),
                          _vm._v(" "),
                          _c("v-list-item-title", [
                            _vm._v("\n            Login\n          ")
                          ])
                        ],
                        1
                      )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-main", [_c("router-view")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }