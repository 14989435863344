

export const headerItems = [
  {icon: 'mdi-home', text: 'Home', route: '/'},
  {icon: 'mdi-layers-search-outline', text: 'Karte', route: '/Karte'},
  {icon: 'mdi-file-table-box-multiple-outline', text: 'Datenbank', route: '/Datenbank', rolle: ['Administrator']},
  {icon: 'mdi-text-account', text: 'Benutzerverwaltung', route: '/Benutzerverwaltung', rolle: ['Administrator']},
  {icon: 'mdi-cog-outline', text: 'Einstellungen', route: '/Einstellungen', rolle: ['Administrator']},
  {icon: 'mdi-robot-industrial', text: 'SyncButler', route: '/SyncButler', rolle: ['Administrator', 'Bearbeiter']},
  {icon: 'mdi-calendar', text: 'Erhebungsperioden', route: '/Erhebungsperioden', rolle: ['Administrator']}
];
