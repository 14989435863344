var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "pa-5 inthemiddle",
      attrs: { width: "340px", height: "240px", elevation: "4" }
    },
    [
      _c(
        "v-form",
        { attrs: { method: "POST", action: "/api/login" } },
        [
          _c("v-text-field", {
            ref: "focusMe",
            attrs: {
              name: "username",
              label: "Benutzername",
              placeholder: "Benutzername",
              single: "",
              outlined: "",
              required: ""
            }
          }),
          _vm._v(" "),
          _c("v-text-field", {
            attrs: {
              name: "password",
              label: "Passwort",
              placeholder: "Password",
              required: "",
              single: "",
              outlined: "",
              type: "password"
            }
          }),
          _vm._v(" "),
          _c("input", {
            attrs: { name: "next", type: "hidden" },
            domProps: {
              value: _vm.$route.query.next ? _vm.$route.query.next : "/"
            }
          }),
          _vm._v(" "),
          _c("input", {
            attrs: { name: "fail", type: "hidden", value: "/Login" }
          }),
          _vm._v(" "),
          _c("v-btn", { attrs: { type: "submit", color: "primary" } }, [
            _vm._v("\n      Login\n    ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }